import React, { startTransition, useCallback, useState, useEffect } from 'react';
import { NavLink as Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { CLIENT_URL, DISCORD_LINK, SPOTIFY_SCOPE, isValidEmail } from 'core/utilities';
import { useForm, useSessionStorage, useToast } from 'view/hooks';
import { MediaQuery, Palette } from 'core/config';
import { Modal, TextInput } from '../common';
import { supabase } from 'supabaseClient';
import { useAuthStore } from 'core/store';

const Navbar: React.FunctionComponent = () => {
  const [userSessionPlaylist, setUserSessionPlaylist] = useSessionStorage('current-playlist', '');
  const [isPlaylistDirty, setIsPlaylistDirty] = useSessionStorage(
    'current-playlist-changed',
    'false'
  );
  const [sessionPlaylistTracks, setSessionPlaylistTracks] = useSessionStorage(
    'current-playlist-tracks',
    ''
  );

  // There is a delay in updating session state when user opens a new tabs and is already logged in.
  // Should we check for session storage in local storae first, then update state.
  const [session, resetAuth] = useAuthStore((state) => [state.session, state.resetAuth]);
  const [searchParams] = useSearchParams();

  const [
    emailConfirmed,
    addEmailConfirmed,
    superPlaylist,
    addSuperPlaylist,
    addCurrentTrackHovered,
    spotifyUserProfile,
  ] = useAuthStore((state) => [
    state.emailConfirmed,
    state.addEmailConfirmed,
    state.superPlaylist,
    state.addSuperPlaylist,
    state.addCurrentTrackHovered,
    state.spotifyUserProfile,
  ]);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [errorDescription, setErrorDescription] = useState('');
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openMagicLink, setOpenMagicLink] = useState(false);
  const [isSignupFlow, setIsSignupFlow] = useState(false);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [magicLink, setMagicLink] = useState(false);

  useEffect(() => {
    // http://localhost:3000/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzEzOTM1MjIxLCJpYXQiOjE3MTM5MzE2MjEsImlzcyI6Imh0dHA6Ly8xMjcuMC4wLjE6NTQzMjEvYXV0aC92MSIsInN1YiI6ImU1YzRmZmVjLWIzMzQtNDRjNC04OTM2LTJlNmJlNzI1ZGZhYiIsImVtYWlsIjoiY2FsbGFzdG9AZ21haWwuY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJzcG90aWZ5IiwicHJvdmlkZXJzIjpbInNwb3RpZnkiXX0sInVzZXJfbWV0YWRhdGEiOnsiYXZhdGFyX3VybCI6Imh0dHBzOi8vaS5zY2RuLmNvL2ltYWdlL2FiNjc3NTcwMDAwMDNiODIxMWM3YTYwMDVlNzBlNjlmNzVlNDRjNjIiLCJlbWFpbCI6ImNhbGxhc3RvQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZnVsbF9uYW1lIjoiY2FsbGFzdG8iLCJpc3MiOiJodHRwczovL2FwaS5zcG90aWZ5LmNvbS92MSIsIm5hbWUiOiJjYWxsYXN0byIsInBob25lX3ZlcmlmaWVkIjpmYWxzZSwicGljdHVyZSI6Imh0dHBzOi8vaS5zY2RuLmNvL2ltYWdlL2FiNjc3NTcwMDAwMDNiODIxMWM3YTYwMDVlNzBlNjlmNzVlNDRjNjIiLCJwcm92aWRlcl9pZCI6ImNhbGxhc3RvIiwic3ViIjoiY2FsbGFzdG8ifSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwiYW1yIjpbeyJtZXRob2QiOiJvYXV0aCIsInRpbWVzdGFtcCI6MTcxMzkzMTYyMX1dLCJzZXNzaW9uX2lkIjoiMDljYzk2NTctZjllMC00NTZlLTlmZTQtZmVmN2FlM2QyOWExIiwiaXNfYW5vbnltb3VzIjpmYWxzZX0.rBxvLWQDZ_zABZpUeggXKBrYYirzuqdbT-HQAWL5tU4&expires_at=1713935221&expires_in=3600&provider_refresh_token=AQDItMIQaq6gxy60bPYH8MU-K43hhsQF6_Na7NhAlXiDei_HnKvDw7GLHqZbaJ-9DFy1DYEkNskOInV8a3CDtYTLTZjnbksme5hXx5eKdWX_w5auo8-s6bHhYGFlhqFgQtE&provider_token=BQDppJE9URq6UNaNs-hjfUx8ZUdMjPo6tdteicRe1M6db7Lf4HUYs77d60MeHRiZeaOOPyrWQN94ETVAxYrwEBcNYSInZAFNfjkKwfaLz18C4oUD2y6zvtkiDfGEsjlQWi1jn1BEVqt6ZC8CZy0Bi7BktniJ0jHTOZ3rLb9o4ms7aztTxogcsTfy2IcMsvg5ij50Tp23-jY2-lnpVlkGfF6z23-VXFyigBmkDdJHGzAs6TV4gcvJ4eSUNaag5llpvt0qNiaB&refresh_token=u0sYIr61lZKglda0NhOChg&token_type=bearer
    // http://localhost:3000/?error=access_denied&error_code=422&error_description=Unverified+email+with+spotify.+A+confirmation+email+has+been+sent+to+your+spotify+email#error=access_denied&error_code=422&error_description=Unverified+email+with+spotify.+A+confirmation+email+has+been+sent+to+your+spotify+email

    // `http://localhost:3000/
    // ?error=unauthorized_client
    // &error_code=401
    // &error_description=Unverified+email+with+spotify.+A+confirmation+email+has+been+sent+to+your+spotify+email
    // #error=unauthorized_client
    // &error_code=401
    // &error_description=Unverified+email+with+spotify.+A+confirmation+email+has+been+sent+to+your+spotify+email`

    // const tokenType = searchParams.get('token_type');

    let timeout: NodeJS.Timeout;

    const errorCode = searchParams.get('error_code');
    const errorDescription = searchParams.get('error_description');
    if (errorCode && errorDescription) {
      setErrorDescription(errorDescription);
      timeout = setTimeout(() => setOpenSubModal(true), 500);
      // if (errorCode === '422') {
      //   // Use timeout so modal doesn't open immediately
      //   timeout = setTimeout(() => setOpenSubModal(true), 500);
      // }
    }

    return () => clearTimeout(timeout);
  }, [searchParams, showToast]);

  const handleModalClose = () => {
    setOpenSubModal(false);
    // Clear search params???
    // navigate({ search: '' });
  };

  const handleLogin = async (signup?: boolean) => {
    const { error } = await supabase.auth.signInWithOAuth({
      options: { scopes: SPOTIFY_SCOPE, redirectTo: `${CLIENT_URL}${!signup ? '/mixer' : ''}` },
      provider: 'spotify',
    });
    // handlePlaylistReset() ??
    // showToast('Signed in', 'success');
    if (emailConfirmed) addEmailConfirmed(false);
    if (error) {
      console.error(error);
      showToast('Login failed', 'failure');
    }
  };

  const handleLogout = async () => {
    // Reset all user and playlist data first
    resetAuth();
    handlePlaylistReset();

    // Then sign out user
    const { error } = await supabase.auth.signOut();

    // showToast('Signed out', 'success');

    if (error) {
      console.error(error);
      showToast('Logout failed', 'failure');
    }
  };

  const handlePlaylistReset = () => {
    if (superPlaylist['A']?.length === 0) return;
    addSuperPlaylist({ A: [], B: [] });
    addCurrentTrackHovered(null);

    if (isPlaylistDirty === 'true') setIsPlaylistDirty('false');
    if (sessionPlaylistTracks) setSessionPlaylistTracks('');
    if (userSessionPlaylist) setUserSessionPlaylist('');
  };

  const handleMagicLink = async (values: any) => {
    setIsSubmitting(true);
    const { error } = await supabase.auth.signInWithOtp({
      email: values.email,
      options: {
        // set this to false if you do not want the user to be automatically signed up
        shouldCreateUser: false,
        emailRedirectTo: CLIENT_URL,
      },
    });

    if (error?.message) {
      showToast('Magic link failed.', 'failure');
      console.error(error);
    }

    if (!error?.message) {
      showToast('Magic link sent. Please check your email to sign back in', 'success');
    }

    setIsSubmitting(false);
    setMagicLink(true);
  };

  const handleLinkClick = (href: string) => (e: any) => {
    e?.preventDefault();

    // if (openMoreLinks) setOpenMoreLinks(false);

    startTransition(() => {
      navigate(href);
    });
  };

  const initialValues = { email: '' };

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: string } = {};

    if (!values.email.trim()) {
      errors.email = 'Please enter your email.';
    }

    if (values.email && !isValidEmail(values.email)) {
      errors.email = 'Please enter a valid email.';
    }

    return errors;
  }, []);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched, isValid } = useForm({
    initialValues,
    validate,
    onSubmit: handleMagicLink,
  });

  return (
    <Header>
      <Container>
        <div>
          <NavLink to="/" onClick={handleLinkClick('/')}>
            SPMixer
          </NavLink>
          <NavLink to="/mixer" onClick={handleLinkClick('/mixer')}>
            / Mixer
          </NavLink>
          <NavLink to="/pricing" onClick={handleLinkClick('/pricing')}>
            / Pricing
          </NavLink>
          <NavLink to="/network" onClick={handleLinkClick('/network')}>
            / Join Network
          </NavLink>
          <NavLink to="/about" onClick={handleLinkClick('/about')}>
            / About
          </NavLink>
          <NavLink to="/faq" onClick={handleLinkClick('/faq')}>
            / FAQ
          </NavLink>
          <NavLink to="/legal" onClick={handleLinkClick('/legal')}>
            / Terms & Policies
          </NavLink>
        </div>
        <AccountWrapper>
          {session?.access_token ? (
            <React.Fragment>
              <UserImage
                src={spotifyUserProfile?.images[0].url ?? session.user.user_metadata?.avatar_url}
                alt="User Avatar"
              />
              <UserName>
                {spotifyUserProfile?.display_name ?? session.user?.user_metadata?.name}
              </UserName>
              {'| '}
              <NavLink to="/account" onClick={handleLinkClick('/account')}>
                Account
              </NavLink>
              <Button onClick={handleLogout}>Logout</Button>
            </React.Fragment>
          ) : (
            <Button
              aria-label="Login, sign up or send magic login link"
              onClick={() => setOpenLoginModal(true)}
            >
              Login / Sign up
            </Button>
          )}
        </AccountWrapper>
      </Container>
      <Modal
        title={
          isSignupFlow && !openMagicLink
            ? 'Sign up'
            : openMagicLink
            ? 'Send Magic Link Email'
            : 'Login or Sign up'
        }
        show={openLoginModal}
        isBack={!(isSignupFlow || openMagicLink)}
        onClose={() => {
          setOpenLoginModal(false);
          setOpenMagicLink(false);
          setIsSignupFlow(false);
        }}
        onBack={() => {
          setOpenMagicLink(false);
          setIsSignupFlow(false);
        }}
      >
        <div>
          {isSignupFlow && !openMagicLink ? (
            <div>
              <div>
                <ModalTitle>Spotify Data Transparency:</ModalTitle>
                <TransparencyContainer>
                  <ModalTitle $alignLeft>After signing up, SPMixer will:</ModalTitle>
                  <li>access your Spotify email for authentication and account updates.</li>
                  <li>
                    access your profile for displaying your user name/avatar and settings (e.g.,
                    subscription type, streaming explicit content).
                  </li>
                  <li>read, modify, create or delete your public and private playlists.</li>
                  <li>
                    stream and modify playback state between Spotify's and SPMixer's audio player.
                  </li>
                </TransparencyContainer>
                <Paragraph>
                  By clicking 'Sign up', you agree to our{' '}
                  <a
                    href={`${CLIENT_URL}/legal/terms-of-service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={`${CLIENT_URL}/legal/privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Paragraph>
                <Paragraph>
                  *You'll be briefly redirected to spotify.com to grant SPMixer access to the data
                  and permissions listed above.
                </Paragraph>
              </div>
              <HR />
              <Button
                tabIndex={0}
                aria-label="Sign up to SPMixer"
                onClick={() => handleLogin(true)}
              >
                Sign up
              </Button>
            </div>
          ) : openMagicLink ? (
            <div>
              <TextInput
                placeholder="neo@thematrix.com"
                autoComplete="email"
                type="email"
                name="email"
                label="Enter your Spotify email used for SPMixer"
                aria-label="Enter your email"
                helperText="Provide your email address to send magic login link."
                minLength={4}
                maxLength={256}
                onBlur={handleBlur}
                value={values.email}
                onChange={handleChange}
                disabled={isSubmitting}
                touched={touched.email}
                error={errors.email}
                required
              />
              <Button
                tabIndex={0}
                aria-label="Send magic login link"
                onClick={handleSubmit}
                disabled={isSubmitting || !isValid}
              >
                Send Magic Link
              </Button>
            </div>
          ) : (
            <div>
              <ModalTitle>Login or sign up via Spotify</ModalTitle>
              <HR />
              <Button
                tabIndex={0}
                aria-label="Sign up to SPMixer"
                onClick={() => setIsSignupFlow(true)}
              >
                Sign up
              </Button>
              or
              <Button tabIndex={0} aria-label="Login to SPMixer" onClick={() => handleLogin()}>
                Login
              </Button>
              <HR />
              <ModalTitle $alignLeft>
                Already have an account, but can't login through Spotify?
              </ModalTitle>
              <HR />
              <Button
                tabIndex={0}
                aria-label="Send magic login link"
                onClick={() => setOpenMagicLink(true)}
              >
                {magicLink ? 'Magic Link Sent' : 'Send Magic Link'}{' '}
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal title="Email Confirmed" show={emailConfirmed} onClose={() => addEmailConfirmed(false)}>
        <div>
          <ModalTitle $alignLeft>Thank you for confirming your email.</ModalTitle>
          <Paragraph>
            You can now access SPMixer! <br /> <br />
            If you have any questions or feedback, please reach out to us on the{' '}
            <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
              discord server
            </a>{' '}
            🌐
          </Paragraph>
          <HR />
          <Button tabIndex={0} aria-label="Log back in to SPMixer" onClick={() => handleLogin()}>
            Log in
          </Button>
        </div>
      </Modal>
      <Modal title="Confirm your email" show={openSubModal} onClose={handleModalClose}>
        <div>{errorDescription}</div>
      </Modal>
    </Header>
  );
};

const Header = styled.header`
  position: fixed;
  width: 100vw;
  height: 9vh;
  display: flex;
  align-items: center;
  padding-left: 25px;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 200;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Button = styled.button`
  background-color: transparent;
  padding: 10px;
  margin: 0 7px;
  font-weight: bold;

  &:disabled {
    cursor: not-allowed;
  }
  /* border: 1px solid white; */
`;

const NavLink = styled(Link)`
  line-height: 30px;
  margin: 0 7px;
  &.active {
    border-bottom: 2px solid ${Palette.SPOTIFY_GREEN};
  }
  &:hover {
    border-bottom: 2px solid ${Palette.SPOTIFY_GREEN};
  }
`;

const AccountWrapper = styled.div`
  display: none;
  @media ${MediaQuery.tablet} {
    display: flex;
    align-items: center;
  }
`;

const UserName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  margin-right: 8px;
`;

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 50%;
  border: 1px solid white;
`;

const TransparencyContainer = styled.ul`
  width: 100%;
  /* height: 200px;
  overflow-y: scroll; */
  text-align: left;
  border: 2px solid ${Palette.WHITE};
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  li {
    margin-top: 5px;
    margin-left: 25px;
  }
`;

const ModalTitle = styled.h4<{ $alignLeft?: boolean }>`
  text-align: ${({ $alignLeft }) => ($alignLeft ? 'start' : 'center')};
`;

const Paragraph = styled.p`
  margin-top: 15px;
  text-align: start;
  a {
    color: ${Palette.SPOTIFY_GREEN};
    text-decoration: underline;
  }
`;

const HR = styled.hr`
  width: 100%;
  border: 1px solid ${Palette.WHITE};
  margin: 16px 0px;
`;

// const Title = styled.h1`
//   font-size: 2em;
//   font-weight: bold;
//   font-style: italic;
//   color: #e63b60;
//   text-align: center;
//   &:before {
//     content: '/';
//     color: ${Palette.SPOTIFY_GREEN};
//   }
// `;

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

export default Navbar;
